import { useEffect, useRef, useState, useContext } from 'react'
import { useMemoizedContentGetter } from 'utils/contentful'
import { LinkButton, Typography } from 'components/Contentful'
import Section from 'components/Section'
import { Grid, Box, Link, Paper, Slider, AchieveTheme } from '@achieve/ascend'
import classNames from 'classnames'
import { ArrowRight } from 'react-feather'
import { Eyebrow } from 'components/Eyebrow'
import { BLOCKS } from '@contentful/rich-text-types'
import { IconListItem } from 'components/IconListItem'
import { HandwrittenText } from 'components/HandwrittenText'
import { unslug } from 'utils/routes'
import { useRouter } from 'next/router'
import { SubnavSectionModule } from 'components/SubnavSection'
import { AchieveLink } from 'components/AchieveLink'
import { PhoneDataContext } from 'providers/PhoneDataProvider'
import { LayoutContext } from 'providers/LayoutProvider'
import { Phone } from 'react-feather'
import { CtaCallButton } from 'components/CtaCallButton'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import { DirectMailerLink } from 'components/DirectMailerLink'
import { addUrlParameter, removeUrlParams } from 'utils/shared'
import { GET_STARTED_ROUTE_DR } from 'constants/navigation'
import { mainProductConfig } from 'services/contentful/helpers'
import { getSlug } from 'utils/conversions'

/*
Home hero configs
{
  "color": "white",
  "contentBackground": "blue",
  "backgroundObjectPosition": "right",
  "backgroundObjectPositionMobile": "top",
  "heroVariant": "home",
}
*/

/*
Debt consolidation hero configs
{
  "backgroundObjectPosition": "right bottom",
  "backgroundObjectPositionMobile": "center",
  "color": "white",
  "contentBackground": "blue",
  "heroVariant": "debt-consolidation",
  "textAlign": {
      "mobile": "center",
      "desktop": "center"
  }
}
 */

function HomeHeroV2({ content }) {
  const {
    background,
    backgroundConfigJson: { jsonContent },
    button,
    eyebrow,
    handwrittenImg,
    iconListItems,
    link,
    subTitle,
    title,
    subnav,
    sliderConfig,
    directMailerLink,
    event,
  } = useMemoizedContentGetter(content, [
    'background',
    'backgroundConfigJson',
    'button',
    'eyebrow',
    'handwrittenImg',
    'iconListItems',
    'link',
    'subTitle',
    'title',
    'subnav',
    'sliderConfig',
    'directMailerLink',
    'event',
  ])

  const hasPhone = content?.fields?.additionalConfiguration?.fields?.jsonContent?.phone
  const phoneWrapper = content?.fields?.additionalConfiguration?.fields?.jsonContent?.phoneWrapper
  const overlay = content?.fields?.additionalConfiguration?.jsonContent?.overlay

  let newButton
  if (phoneWrapper) {
    newButton = { ...button }
    newButton.linkText.json.content[0].content[0].value = 'Get Started'
  }

  return (
    <HomeHeroV2Module
      background={background}
      backgroundImageAlt={background?.mediaAltText}
      isMobileUA={content?.isMobileUA}
      eyebrow={eyebrow}
      title={title}
      subTitle={subTitle}
      link={link}
      button={phoneWrapper ? newButton : button}
      jsonConfig={jsonContent}
      handwrittenImg={handwrittenImg}
      iconListItems={iconListItems}
      subnav={subnav}
      sliderConfig={sliderConfig}
      hasPhone={hasPhone}
      phoneWrapper={phoneWrapper}
      directMailerLink={directMailerLink}
      event={event}
      overlay={overlay}
    />
  )
}

function HomeHeroV2Module({
  background,
  eyebrow,
  title,
  subTitle,
  link,
  button,
  jsonConfig,
  handwrittenImg,
  iconListItems,
  subnav,
  sliderConfig,
  hasPhone = false,
  phoneWrapper = false,
  directMailerLink,
  event,
  overlay,
}) {
  const phoneData = useContext(PhoneDataContext)
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  /// Get all the config values from the jsonConfig
  const {
    heroVariant,
    // mobilePadding = 'bottom',
    backgroundObjectPosition = 'right',
    backgroundObjectPositionMobile = 'bottom',
    titleVariantMobile = 'headingLg',
    titleVariant = 'displayXl',
    subTitleVariantMobile = 'bodyLg',
    subTitleVariant = 'bodyLg',
    showPattern = false,
    contentBackground,
    // backgroundNoCss = true,
  } = jsonConfig

  const directMailerFlag = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_DIRECT_MAIL_LINK)
  const router = useRouter()

  const textContainerRef = useRef(null)

  const isDcVariation = heroVariant === 'debt-consolidation'

  const backgroundConfig = jsonConfig?.background ?? 'bgDefault'

  const sliderProps = {
    ...sliderConfig?.jsonContent,
    ctaText: button?.linkText?.json?.content?.[0]?.content[0]?.value || 'Get started',
    ctaLinkBase: button?.linkHref || GET_STARTED_ROUTE_DR,
    ctaEvent: button?.event,
  }

  const eyebrowColor = eyebrow?.fontColor?.toLowerCase()
  const eyebrowPosition = eyebrow?.position?.toLowerCase()

  return (
    <section className={classNames('relative max-w-full')}>
      {subnav && (
        <Grid
          container
          className={classNames(
            'justify-center sticky z-[9] bg-white -translate-y-px transition-[top] duration-[0.05s] ease-linear delay-[0.05s]'
          )}
          data-testid="subnav-container"
        >
          <div>
            <SubnavSectionModule grid={subnav?.sectionContents[0]?.fields} />
          </div>
        </Grid>
      )}
      <Section
        className={
          'group [&[data-hero-variant="debt-consolidation"]]:max-lg:h-max [&[data-hero-variant="debt-consolidation"]]:max-md:h-max'
        }
        data-testid="HomeHeroV2_section"
        data-narrow={true}
        data-hero-variant={heroVariant}
        mediaContent={background?.mediaContent}
        middleMediaContent={background?.middleMediaContent || background?.mediaContent}
        mobileMediaContent={background?.mobileMediaContent || background?.mediaContent}
        backgroundImageAlt={background?.mediaAltText}
        showPattern={showPattern}
        backgroundObjectPositionClass={`bg-${getSlug(
          backgroundObjectPositionMobile
        )} lg:bg-${getSlug(backgroundObjectPosition)}`}
        variant={backgroundConfig}
        preloadBackground={true}
        backgroundNoCss={true}
      >
        <Box
          className={classNames(
            'relative flex text-center min-h-[65vh]',
            'xl:min-h-[723px] xl:py-[80] xl:px-0',
            '2xl:max-w-full 2xl:w-full 2xl:h-[initial] 2xl:m-0',
            'group-data-[hero-variant="debt-consolidation"]:min-h-[30vh]',
            'group-data-[hero-variant="debt-consolidation"]:md:min-h-[22vh] group-data-[hero-variant="debt-consolidation"]:lg:min-h-[363px] group-data-[hero-variant="debt-consolidation"]:lg:p-0 group-data-[hero-variant="debt-consolidation"]:xl:min-h-[inherit] group-data-[hero-variant="debt-consolidation"]:xl:p-0'
          )}
          component="div"
        >
          <Grid
            container
            alignItems="center"
            className={classNames(
              'flex ${contentDirectionMobile} lg:${contentDirection} w-full items-center',
              backgroundObjectPosition === 'left' ? 'lg:flex-row-reverse' : 'lg:flex-row',
              backgroundObjectPositionMobile === 'top' ? 'flex-col-reverse' : 'flex-col'
            )}
            data-testid="closer-fullbg-outer-grid-container"
          >
            {/* The content grid item */}
            <Grid
              item
              className={classNames(
                'text-left',
                'justify-center',
                'flex-col',
                'max-w-[447px]',

                // home variant
                'group-data-[hero-variant="home"]:max-w-[447px]',
                'group-data-[hero-variant="home"]:lg:max-w-[506px]',

                // debt-consolidation variant
                'group-data-[hero-variant="debt-consolidation"]:pt-[246px]',
                'group-data-[hero-variant="debt-consolidation"]:px-0',
                'group-data-[hero-variant="debt-consolidation"]:max-w-[375px]',
                'group-data-[hero-variant="debt-consolidation"]:max-md:max-w-[340px]',
                'group-data-[hero-variant="debt-consolidation"]:max-md:pt-[215px]',
                'group-data-[hero-variant="debt-consolidation"]:md:max-w-[462px]',
                'group-data-[hero-variant="debt-consolidation"]:lg:max-w-[340px]',
                'group-data-[hero-variant="debt-consolidation"]:lg:px-0',
                'group-data-[hero-variant="debt-consolidation"]:lg:py-[48px]',
                'group-data-[hero-variant="debt-consolidation"]:xl:gap-[48px]',
                'group-data-[hero-variant="debt-consolidation"]:xl:max-w-[462px]',
                'group-data-[hero-variant="debt-consolidation"]:xl:py-[48px]'
              )}
              data-testid="closer-fullbg-grid-item-text"
              container
            >
              {overlay && (
                <div
                  className={classNames(
                    '-mt-10',
                    'mb-5',
                    'md:text-right',
                    'lg:absolute',
                    'lg:bottom-14',
                    'lg:m-0',
                    'lg:right-0',
                  )}
                >
                  <Typography
                    id="name-overlay"
                    className={classNames(
                      'bg-[#154199]',
                      'font-medium',
                      'inline-block',
                      'px-4',
                      'py-2',
                      'rounded-bl-[10px]',
                      'rounded-br-[20px]',
                      'rounded-tl-[10px]',
                      'rounded-tr-[0]',
                      'text-white',
                      'text-xs',
                      'whitespace-nowrap',
                      'md:rounded-bl-[20px]',
                      'md:rounded-br-[10px]',
                      'md:rounded-tl-[0]',
                      'md:rounded-tr-[10px]',
                      'md:text-sm',
                      'md:translate-x-32',
                      'lg:translate-x-0',
                    )}
                    content={overlay}
                  />
                </div>
              )}
              <div
                className={classNames(
                  'relative z-[1] translate-y-2/4',
                  'data-[container-color="blue"]:bg-[#3300ff] data-[container-color="blue"]:p-6 data-[container-color="blue"]:rounded-[10px] group-data-[hero-variant="debt-consolidation"]:[&>*+*]:block group-data-[hero-variant="debt-consolidation"]:[&>*+*]:mt-4 xl:[transform:none] data-[container-color="blue"]:xl:p-[40px] group-data-[hero-variant="debt-consolidation"]:[transform:inherit] group-data-[hero-variant="debt-consolidation"]:data-[container-color="blue"]:p-8  group-data-[hero-variant="debt-consolidation"]:data-[container-color="blue"]:[background:linear-gradient(172.38deg,_#30f_30.04%,_#71b4ff_144.48%,_#ddf3ff_169.51%)] lg:transform-none data-[container-color="blue"]:xl:py-8 data-[container-color="blue"]:xl:px-0 lg:group-data-[hero-variant="debt-consolidation"]:data-[container-color="blue"]:px-[40px]'
                )}
                ref={textContainerRef}
                data-container-color={contentBackground}
                data-testid="closer-fullbg-text-container"
              >
                {eyebrow && (
                  <Eyebrow
                    content={eyebrow?.textContent}
                    fontWeight="bold"
                    component="h1"
                    className={classNames(
                      'pb-0 ',
                      eyebrowColor === 'white'
                        ? 'text-white'
                        : eyebrowColor === 'black'
                        ? 'text-black'
                        : eyebrowColor === 'blue'
                        ? 'text-blue'
                        : 'font-white' // },
                          `text-${eyebrow?.position?.toLowerCase()}`,
                      eyebrowPosition === 'left'
                        ? 'text-left'
                        : eyebrowPosition === 'center'
                        ? 'text-center'
                        : 'text-right'
                    )}
                    data-testid="HomeHeroV2_eyebrow"
                  />
                )}
                {title && (
                  <Typography
                    content={title?.textContent}
                    variant={titleVariant}
                    mobileVariant={titleVariantMobile}
                    breakpoint="md"
                    responsive={true}
                    fontWeight="bold"
                    className={classNames(
                      `mb-4 ${
                        eyebrow?.textContent ? 'mt-[8px_!important]' : ''
                      } xl:mt-[8px_!important]`,
                      `text-${title?.fontColor?.toLowerCase()}`
                    )}
                    data-testid="HomeHeroV2_title"
                  />
                )}
                {subTitle && (
                  <Typography
                    content={subTitle?.textContent}
                    variant={subTitleVariant}
                    mobileVariant={subTitleVariantMobile}
                    breakpoint={isDcVariation ? 'lg' : 'md'}
                    responsive={true}
                    className={classNames(
                      'mt-[16px_!important] md:mb-[16px_!important] group-data-[hero-variant="debt-consolidation"]:lg:pb-[8px_!important]',
                      `text-${subTitle?.fontColor?.toLowerCase()}`
                    )}
                    data-testid="HomeHeroV2_subtitle"
                  />
                )}
                {iconListItems?.gridItems?.length > 0 && (
                  <div className={classNames('flex items-center justify-center ')}>
                    <ul className="m-0 p-0">
                      {iconListItems.gridItems.map((icon, index) => {
                        return (
                          <IconListItem
                            iconColor={icon.fields.iconColor}
                            iconName={icon.fields.iconName}
                            itemText={icon.fields.description}
                            key={`icon-list-item-${index}`}
                            testIdPrefix="home-hero"
                            textColor={AchieveTheme?.ascend?.colors?.neutral?.[0]}
                            className="w-[20px] h-[20px] lg:w-[30px] lg:h-[30px]"
                            classNameText={'bodySm lg:headingMd'}
                          />
                        )
                      })}
                    </ul>
                  </div>
                )}
                {link && !sliderConfig && (
                  <Link underline="hover" fontWeight="bold" variant="bodyLg" href={link?.linkHref}>
                    <Typography
                      content={link?.linkText?.json}
                      fontWeight="bold"
                      className={'inline'}
                      variant="bodyLg"
                      mobileVariant="bodyLg"
                      breakpoint="md"
                      responsive={true}
                    />{' '}
                    <ArrowRight className={'pt-1'} />
                  </Link>
                )}
                {button && !sliderConfig && (
                  <CtaCallButton phoneWrapper={phoneWrapper} variant="white">
                    <LinkButton
                      track={{
                        ...{
                          list_name: 'Home Hero CTA click',
                          nav_link_section: `${
                            removeUrlParams(router.asPath) === '/'
                              ? 'Home'
                              : unslug(router.asPath).slice(1)
                          } Hero`,
                          click_id: button?.linkText?.json?.content?.[0]?.content[0]?.value,
                          click_text: button?.linkText?.json?.content?.[0]?.content[0]?.value,
                          click_url: button?.linkHref,
                          track_event: 'internal_campaign_click',
                          event_action: 'button_click',
                          event_type: 'button_click',
                        },
                        ...button.event,
                      }}
                      typographicOptions={{
                        variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                        fontWeight: 'bold',
                      }}
                      variant="contained"
                      color="secondary"
                      content={button}
                      className={'w-full max-w-[280px] text-[#3300ff]'}
                      underline="hover"
                    />
                  </CtaCallButton>
                )}
                {sliderConfig && <SliderTool {...sliderProps} title={'titleText'} />}

                {directMailerFlag && directMailerLink && (
                  <DirectMailerLink
                    content={directMailerLink}
                    track={{
                      list_name: 'Home Hero Direct Mailer Click',
                      nav_link_section: `${
                        removeUrlParams(router.asPath) === '/'
                          ? 'Home'
                          : unslug(router.asPath).slice(1)
                      } Hero`,
                      click_id: directMailerLink?.linkText?.json?.content?.[0].content?.[0].value,
                      click_text: directMailerLink?.linkText?.json?.content?.[0].content?.[0].value,
                      click_url: directMailerLink.linkHref,
                    }}
                  />
                )}

                {hasPhone && phoneData.phoneNumber && (
                  <AchieveLink
                    className={'decoration-white'}
                    href={`tel:${phoneData.phoneNumber}`}
                    noLink
                    track={{
                      ...{
                        list_name: 'Hero V2 Phone',
                        click_id: phoneData.phoneNumber,
                        click_text: `${
                          mainProductConfig[siteEnv || 'achieve']?.['MainProduct']
                        } | ${phoneData.phoneNumber}`,
                        click_type: 'Phone Click',
                        nav_link_section: 'Hero',
                        track_event: 'button_click',
                      },
                      ...event,
                    }}
                    underline="hover"
                  >
                    <div className={'flex flex-row justify-center mt-4 md:justify-left'}>
                      {/* Empty div to ignore !important css making phone image 345px tall */}
                      <div></div>
                      <Phone
                        alt="phone button"
                        width={24}
                        height={24}
                        color={AchieveTheme?.ascend?.colors?.neutral?.[0]}
                      />
                      <Typography
                        fontWeight="bold"
                        variant="bodyLg"
                        mobileVariant="bodyLg"
                        breakpoint="md"
                        responsive={true}
                        className={'text-white ml-2 mr-0 my-0'}
                        content={phoneData.phoneNumber}
                      />
                    </div>
                  </AchieveLink>
                )}
              </div>

              {handwrittenImg && (
                <div
                  className={'hidden md:items-center md:flex md:justify-center'}
                  data-hero-variant={heroVariant}
                >
                  <div
                    className={'h-[63px] text-center w-[410px] bottom-0'}
                    data-hero-variant={heroVariant}
                  >
                    <HandwrittenText
                      alt={handwrittenImg?.mediaAltText}
                      content={handwrittenImg?.mediaContent}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      </Section>
    </section>
  )
}

function SliderTool({ ctaEvent, ctaText, ctaLinkBase, slider, sliderQueryParam }) {
  const [sliderValue, setSliderValue] = useState(
    slider.defaultValue ? slider.defaultValue : slider.max / 2
  )
  const [ctaHref, setCtaHref] = useState('')
  const dollarUSLocale = Intl.NumberFormat('en-US')

  useEffect(() => {
    setCtaHref(addUrlParameter(ctaLinkBase, sliderQueryParam, sliderValue))
  }, [sliderValue, ctaLinkBase, sliderQueryParam])

  useEffect(() => {
    setSliderValue(slider.defaultValue ? slider.defaultValue : slider.max / 2)
  }, [slider])

  function handleChange(e) {
    setSliderValue(e.target.value)
  }

  return (
    <Paper
      className={
        'shadow-none relative z-[2] bg-transparent lg:relative lg:z-[1] lg:w-full lg:left-0 lg:top-0'
      }
      data-testid="product-hero-slider-container"
    >
      <Grid container>
        <Grid item xs={12}>
          <div
            className={'w-full mt-0 mb-6 mx-0 lg:mt-0 lg:mb-6 lg:mx-0'}
            data-testid="HomeHeroV2_slidertool"
          >
            <Typography
              content={`$${dollarUSLocale.format(sliderValue)}${
                sliderValue === slider.max && slider.maxPlus ? '+' : ''
              }`}
              variant="bodyLg"
              mobileVariant="bodyLg"
              breakpoint="md"
              responsive={true}
              fontWeight="medium"
              className={'text-white text-center'}
              data-testid="product-hero-dollar-value"
            />
            <Slider
              aria-label="dollar-amount-selection-slider"
              className={classNames(
                'group',
                // Child MuiSlider-rail
                '[&_.MuiSlider-rail]:border-0',
                '[&_.MuiSlider-rail]:h-2.5',
                '[&_.MuiSlider-rail]:opacity-100',
                // Child MuiSlider-thumb
                '[&_.MuiSlider-thumb]:bg-[#94bbfe]',
                '[&_.MuiSlider-thumb]:border-[#f5f9ff]',
                '[&_.MuiSlider-thumb]:border-[2px]',
                '[&_.MuiSlider-thumb]:border-solid',
                '[&_.MuiSlider-thumb]:h-5',
                // Child MuiSlider-track
                '[&_.MuiSlider-track]:bg-[#94bbfe]',
                '[&_.MuiSlider-track]:border-0',
                '[&_.MuiSlider-track]:h-2.5',
                '[&_.MuiSlider-track]:text-[#94bbfe]',
              )}
              classes={{
                mark: 'hidden',
              }}
              data-testid="product-hero-slider-input"
              value={sliderValue}
              step={slider.step}
              min={slider.min}
              max={slider.max}
              marks={[
                {
                  value: slider.min,
                  label: (
                    <Typography
                      content={`$${dollarUSLocale.format(slider.min)}`}
                      variant="bodyLg"
                      mobileVariant="bodyLg"
                      breakpoint="md"
                      responsive={true}
                      fontWeight="medium"
                      className={'text-white ml-[50px]'}
                      data-testid="product-hero-slider-min"
                    />
                  ),
                },
                {
                  value: slider.max,
                  label: (
                    <Typography
                      content={`$${dollarUSLocale.format(slider.max)}${slider.maxPlus ? '+' : ''}`}
                      variant="bodyLg"
                      mobileVariant="bodyLg"
                      breakpoint="md"
                      responsive={true}
                      fontWeight="medium"
                      className={'ml-[-80px] text-white'}
                      data-testid="product-hero-slider-max"
                    />
                  ),
                },
              ]}
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <LinkButton
            track={{
              ...{
                list_name: 'Home Hero CTA click',
                click_id: ctaText,
                click_text: ctaText,
                form_ss_amount: sliderValue,
                click_url: ctaHref,
                nav_link_section: 'Home Hero',
                track_event: 'internal_campaign_click',
                event_action: 'button_click',
                event_type: 'button_click',
              },
              ...ctaEvent,
            }}
            content={{
              linkHref: ctaHref,
              linkText: ctaText,
            }}
            typographicOptions={{ variant: 'bodyLg', fontWeight: 'bold' }}
            variant="contained"
            color="secondary"
            className={'min-w-max mt-0'}
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export { HomeHeroV2, HomeHeroV2Module }
export default HomeHeroV2
